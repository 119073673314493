<div mat-dialog-content class="popup">
    <div class="popup-imgs">
        <img src="assets/images/popup-top.png" alt="" class="popup-top" />
        <img src="assets/images/popup-bottom.png" alt="" class="popup-bottom" />
    </div>
    <div class="popup-inner">
        <div class="popup-header">
            <button (click)="onClose()" class="popup-close">
                <img src="assets/images/icon-close.svg" alt="" class="" />
            </button>
        </div>
        <div class="popup-max-hgt">
            <div class="popup-container pt-20">
                <div class="popup-main-img">
                    <img src="assets/images/forgot.png" alt="" />
                </div>
                <div class="line-heading">
                    <h3>Forgot Password</h3>
                </div>
                <p class="text-gray mt-3">Please provide your registered email id to reset your password.</p>
                <form [formGroup]="forgotPasswordForm" class="mt-3">
                    <div class="form-group">
                        <label for="email">Email ID</label>
                        <input type="email" formControlName="email"
                            [ngClass]="{ 'is-invalid': isSubmitted && f.email.errors }" class="form-control"
                            id="exampleInputEmail1" placeholder="Enter Email">
                        <div *ngIf="isSubmitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors['required']">Email is required
                            </div>
                            <div *ngIf="f.email.errors['email']">Enter valid email
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-5 col-12">
                            <button type="button" class="btn btn-primary w-100" (click)="onSubmit()">Submit</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>