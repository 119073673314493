<div mat-dialog-content class="popup">
    <div class="popup-imgs">
        <img src="assets/images/popup-top.png" alt="" class="popup-top" />
        <img src="assets/images/popup-bottom.png" alt="" class="popup-bottom" />
    </div>
    <div class="popup-inner">
        <div class="popup-header">
            <button (click)="onClose()" class="popup-close">
                <img src="assets/images/icon-close.svg" alt="" class="" />
            </button>
        </div>
        <div class="popup-max-hgt">
            <div class="popup-container pt-20">
                <div class="line-heading">
                    <h3>Signup</h3>
                </div>
                <p class="text-gray mt-3">Enter your details below to create your account.</p>
                <form [formGroup]="signUpForm" class="mt-3">
                    <div class="form-group">
                        <label for="name">Name</label>
                        <input type="text" placeholder="Enter Name" formControlName="barName" autocomplete="off"
                            [ngClass]="{ 'is-invalid': isSubmitted && f.barName.errors }" class="form-control" />
                        <div *ngIf="isSubmitted && f.barName.errors" class="invalid-feedback">
                            <div *ngIf="f.barName.errors['required']">Name is required
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email">Email ID</label>
                        <input type="email" formControlName="email" autocomplete="off"
                            [ngClass]="{ 'is-invalid': isSubmitted && f.email.errors }" class="form-control"
                            id="exampleInputEmail1" placeholder="Enter Email">
                        <div *ngIf="isSubmitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors['required']">Email is required
                            </div>
                            <div *ngIf="f.email.errors['email']">enter valid email
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="d-flex align-items-center justify-content-between">
                            <label for="pwd">Password</label>
                        </div>
                        <input type="password" formControlName="password" autocomplete="off"
                            [ngClass]="{ 'is-invalid': isSubmitted && f.password.errors }" class="form-control"
                            id="exampleInputPassword1" placeholder="Enter Password">
                        <div *ngIf="isSubmitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors['required']">Password is required</div>
                            <div *ngIf="f.password.errors['pattern']">
                                Password must be at least 8 characters and should be a mix of special characters,
                                numbers,
                                lowercase and uppercase letters (like Anj1G@de)
                            </div>
                        </div>
                        <div class="password-suggestions">
                            <div>
                                <span>a</span>
                                <span>Lowercase</span>
                            </div>
                            <div>
                                <span>A</span>
                                <span>Uppercase</span>
                            </div>
                            <div>
                                <span>#</span>
                                <span>Special</span>
                            </div>
                            <div>
                                <span>6+</span>
                                <span>Characters</span>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <p class="text-gray">By signing up you are agreeing to our <a href="javascript:void(0);"
                                (click)="openTermsConditionsDialog()" class="text-primary">Terms
                                and Conditions</a></p>
                    </div>
                    <div class="row mt-4 align-items-center">
                        <div class="col mb-3 mb-sm-0">
                            <button type="button" class="btn btn-primary w-100" (click)="onSubmit()">Sign up</button>
                        </div>
                        <div class="col border-left remove-border">
                            <div class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                <span style="white-space: nowrap;">or connect by -</span>
                                <ul class="social-icons">
                                    <li class="facebook">
                                        <a href="">
                                            <img src="assets/images/facebook.svg" alt="" />
                                        </a>
                                    </li>
                                    <li class="apple">
                                        <a href="">
                                            <img src="assets/images/ios.png" alt="" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="text-center mt-3">
                    <p class="text-gray">Already have an account? <a href="javascript:void(0);" (click)="goToLogin()"
                            class="text-primary">Login</a></p>
                </div>
            </div>
        </div>
    </div>
</div>