import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from '../../../core/services/auth-service/auth.service';

@Component({
  selector: 'app-email-sent',
  templateUrl: './email-sent.component.html',
  styleUrls: ['./email-sent.component.css']
})
export class EmailSentComponent implements OnInit {
  text: string = "";

  constructor(
    public dialogRef: MatDialogRef<EmailSentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.text = this.data.text;
  }

  onClose() {
    this.dialogRef.close();
  }

  goToLogin() {
    this.dialogRef.close({ isLogin: false });
    this.authService.popupEventReceived.next({
      gologin: true
    })
  }

}
