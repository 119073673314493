<div mat-dialog-content class="popup">
    <div class="popup-imgs">
        <img src="assets/images/popup-top.png" alt="" class="popup-top" />
        <img src="assets/images/popup-bottom.png" alt="" class="popup-bottom" />
    </div>
    <div class="popup-inner">
        <div class="popup-header">
            <button (click)="onClose()" class="popup-close">
                <img src="assets/images/icon-close.svg" alt="" class="" />
            </button>
        </div>
        <div class="popup-max-hgt">
            <div class="popup-container pt-20">
                <div class="line-heading">
                    <h3>Login</h3>
                </div>
                <p class="text-gray mt-3">Enter your details below to login into your account.</p>
                <form [formGroup]="loginForm" class="mt-3">
                    <div class="form-group">
                        <label for="email">Email ID</label>
                        <input type="email" formControlName="email"
                            [ngClass]="{ 'is-invalid': isSubmitted && f.email.errors }" class="form-control"
                            id="exampleInputEmail1" placeholder="Enter Email">
                        <div *ngIf="isSubmitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors['required']">Email is required
                            </div>
                            <div *ngIf="f.email.errors['email']">enter valid email
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="d-flex align-items-center justify-content-between">
                            <label for="pwd">Password</label>
                            <a href="javascript:void(0);" (click)="openForgotPasswordDialog()" class="text-dark">Forgot
                                Password?</a>
                        </div>
                        <input type="password" formControlName="password"
                            [ngClass]="{ 'is-invalid': isSubmitted && f.password.errors }" class="form-control"
                            id="exampleInputPassword1" placeholder="Enter Password">
                        <div *ngIf="isSubmitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors['required']">Password is required</div>
                        </div>
                    </div>
                    <div class="row mt-4 align-items-center">
                        <div class="col mb-3 mb-sm-0">
                            <button type="button" class="btn btn-primary w-100" (click)="onSubmit()">Log in</button>
                        </div>
                        <div class="col border-left remove-border">
                            <div class="d-flex align-items-center justify-content-center justify-content-sm-start">
                                <span style="white-space: nowrap;">or connect by -</span>
                                <ul class="social-icons">
                                    <li class="facebook">
                                        <a href="javascript:void(0);" (click)="signInWithFB()">
                                            <img src="assets/images/facebook.svg" alt="" />
                                        </a>
                                    </li>
                                    <li class="apple">
                                        <a href="">
                                            <img src="assets/images/ios.png" alt="" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="text-center mt-3">
                    <p class="text-gray">Don't have an account? <a href="javascript:void(0);" (click)="goToSignUp()"
                            class="text-primary">Sign up</a></p>
                </div>
            </div>
        </div>
    </div>
    <!-- Forgot -->
    <!-- <div class="popup-inner">
        <div class="popup-header">
            <button mat-dialog-close class="popup-close">
                <img src="assets/images/icon-close.svg" alt="" class="" />
            </button>
        </div>
        <div class="popup-max-hgt">
            <div class="popup-container pt-20">
                <div class="popup-main-img">
                    <img src="assets/images/forgot.png" alt="" />
                </div>
                <div class="line-heading">
                    <h3>Forgot Password</h3>
                </div>
                <p class="text-gray mt-4">Please provide your registered email id to reset your password.</p>
                <form [formGroup]="loginForm" class="mt-4">
                    <div class="form-group">
                        <label for="email">Email ID</label>
                        <input type="email" formControlName="email"
                        [ngClass]="{ 'is-invalid': isSubmitted && f.email.errors }"
                        class="form-control" id="exampleInputEmail1" placeholder="Enter Email">
                        <div *ngIf="isSubmitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors['required']">Email is required
                            </div>
                            <div *ngIf="f.email.errors['email']">Enter valid email
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-5 col-12">
                            <button type="button" class="btn btn-primary w-100" (click)="onSubmit()">Submit</button>
                        </div>
                    </div>
                </form>
                
            </div>
        </div>
    </div> -->
    <!-- Email Sent -->
    <!-- <div class="popup-inner">
        <div class="popup-header">
            <button (click)="onClose()" class="popup-close">
                <img src="assets/images/icon-close.svg" alt="" class="" />
            </button>
        </div>
        <div class="popup-max-hgt">
            <div class="popup-container pt-20">
                <div class="popup-main-img">
                    <img src="assets/images/email-sent.png" alt="" />
                </div>
                <div class="line-heading">
                    <h3>Email has been sent</h3>
                </div>
                <p class="text-gray mt-4">A email has been sent to your registered email id. Please check your inbox to reset your password.</p>
                <form [formGroup]="loginForm" class="mt-4">                    
                    <div class="row mt-4">
                        <div class="col-md-5 col-12">
                            <button type="button" class="btn btn-primary w-100" (click)="onSubmit()">Login</button>
                        </div>
                    </div>
                </form>
                
            </div>
        </div>
    </div> -->
</div>