import Swal from 'sweetalert2';

export const ToastMessage = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export const SwalConfirm = ({ title = '',
    text = '', icon = null,
    showCancelButton = false,
    confirmButtonColor = '#1F467E',
    cancelButtonColor = '#d33',
    confirmButtonText = 'OK'
}) => {
    return Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: showCancelButton,
        confirmButtonColor: confirmButtonColor,
        cancelButtonColor: cancelButtonColor,
        confirmButtonText: confirmButtonText

    })

}

