export enum Toastr {
    ERROR = 'error',
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning'
}

export enum OrdersEnum {
    ORDERHISTORY = 1,
    STATISTICS
}


