import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EncryptionService } from '../../../core/services/encryption-service/encryption.service';
import { AuthService } from '../../../core/services/auth-service/auth.service';
import * as commonFunction from '../../../core/utils/common-functions/common-functions';
import { SocialAuthService } from "angularx-social-login";
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { LoaderService } from '../../../shared/loader/loader.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/users/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  isSubmitted = false;
  loginForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LoginComponent>,
    private authService: AuthService,
    private encryptionService: EncryptionService,
    private socialAuthService: SocialAuthService,
    private injector: Injector,
    private userService:UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initializationForm();
  }

  initializationForm() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  get f() { return this.loginForm.controls; }

  onClose() {
    this.dialogRef.close({ isLogin: false });
  }

  goToSignUp() {
    this.dialogRef.close({ isLogin: false });
    this.authService.popupEventReceived.next({
      goSignUp: true
    })
  }

  /**
  * Method: openForgotPasswordDialog
  * Purpose : open Forgot password dialog
  */
  openForgotPasswordDialog(): void {
    this.dialogRef.close({ isLogin: false });
    this.dialog.open(ForgotPasswordComponent, {
      width: '560px',
      height: '480px'
    });
  }

  signInWithFB(): void {
    // this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => {
    //   console.log('fb login', x);
    // });;
  }

  signOut(): void {
    this.socialAuthService.signOut();
  }

  async onSubmit() {
    this.isSubmitted = true;
    if (this.loginForm.invalid) {
      return;
    } else {
      this.injector.get(LoaderService).show();
      const visitorId = await commonFunction.getDeviceTokenId();
      let request = {
        'emailId': this.loginForm.value.email,
        'password': this.encryptionService.encryptUsingAES256(this.loginForm.value.password),
        "deviceId": visitorId,
        "deviceType": 1,
      }
      this.authService.login(request).then((res: any) => {
        this.injector.get(LoaderService).hide();
        if (res) {
          var decoded = commonFunction.getDecodedAccessToken(res.data.accessToken);
          localStorage.setItem('accessToken', res.data.accessToken);
          localStorage.setItem('refreshToken', res.data.refreshToken);
          localStorage.setItem('deviceId', this.encryptionService.encryptUsingAES256(visitorId));
          let userId = this.encryptionService.encryptUsingAES256(decoded.userId);
          localStorage.setItem('userId', userId);
          localStorage.setItem('emailId', decoded.emailId);
          localStorage.setItem('firstName', decoded.firstName);
          localStorage.setItem('lastName', decoded.lastName);
          localStorage.setItem('userImage', decoded.userImage);
          this.getProfileDetail(decoded.userId);
          this.authService.loginLogoutEventReceived.next({ isLogin: true, isLogout: false, currentPath: this.router.url });
          this.dialogRef.close({ isLogin: true });
        }
      }).catch(err => {
        
        this.injector.get(LoaderService).hide();
      });
    }
  }


  getProfileDetail(userId:any){
    this.userService.getProfileDetail({userId:userId}).then((res:any)=>{
      let profileData = res?.data
      if(profileData?.barId){
      let barId = this.encryptionService.encryptUsingAES256(profileData?.barId);
      localStorage.setItem('barId',barId);
      }
      else{
      localStorage.setItem('barId',null);
      }
    }).catch((err)=>{
      console.log("err",err);
    })
  }

}
