import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { EncryptionService } from '../services/encryption-service/encryption.service';
import { AuthService } from '../services/auth-service/auth.service';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/catch';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    clientSecret = environment.ClientSecret;

    constructor(
        private authService: AuthService,
        private http: HttpClient,
        private router: Router,
        private encryptionService: EncryptionService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // sending incoming request as it is to the login or forgotPassword api without setting headers
        // tslint:disable-next-line: max-line-length
        if (request.url.indexOf('login') > 0
            || request.url.indexOf('forgot-password') > 0
            || request.url.indexOf('sign-up') > 0
            || request.url.indexOf('reset-password') > 0
            || request.url.indexOf('access-token') > 0
            || request.url.indexOf('set-password') > 0
            || request.url.indexOf('user-email-verify') > 0
            || request.url.indexOf('verify-register-email') > 0
            || request.url.indexOf('verify-password-link') > 0) {
            const setHeader = request.clone({ setHeaders: { client_secret: this.clientSecret } });
            return next.handle(setHeader);
        } else {
            // sending incoming request by appending token to header
            let accessToken = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : '';
            if (accessToken) {
                const setHeader = request.clone({ setHeaders: { access_token: accessToken } });
                return next.handle(setHeader).catch(error => {
                        // to handle 501 unauthorized token and generating refresh token appending it to header
                        // console.log(error.status);
                        if (error instanceof HttpErrorResponse && error.status === 401) {
                            return this.authService.createNewToken().switchMap((res: any) => {
                                const newreq = request.clone({ setHeaders: { access_token: res.data.accessToken } });
                                return next.handle(newreq);
                            });
                        } else {
                            // localStorage.clear();
                            // this.router.navigateByUrl('');
                            return throwError(error);
                        }
                    });
            }else {
                const setHeader = request.clone();
                return next.handle(setHeader);
            }
        }
    }
}
