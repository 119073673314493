import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  public shareCartData: Subject<any> = new Subject<any>();
  public cartDataCount: number = 0;
  shareProfileImage: Subject<any> = new Subject<any>();
}
