import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { DispatcherService } from 'src/app/core/utils/service-dispatcher/dispatcher.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { EncryptionService } from '../encryption-service/encryption.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl!: string;
  baseAppUrl!: string;

  popupEventReceived: Subject<any> = new Subject<any>();
  loginLogoutEventReceived: Subject<any> = new Subject<any>();
  underReviewPopupEvent: Subject<any> = new Subject<any>();

  constructor(
    private dispatcher: DispatcherService,
    private router: Router,
    private encryptionService: EncryptionService
  ) {
    this.baseUrl = environment.baseUrl;
  }

  login(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUrl + 'login', params).subscribe((res: any) => {
        resolve(res);
        //console.log(res);
      }, err => {
        reject(err);
      });
    });
  }

  signup(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUrl + 'sign-up', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  forgotPassword(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUrl + 'forgot-password', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  verifyResetPasswordLink(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(`${environment.baseUrl}reset-password/${params}`, '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  resetPassword(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUrl + 'reset-password', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  createNewToken() {

    let userId = this.encryptionService.decryptUsingAES256(localStorage.getItem('userId'));
    let deviceId = this.encryptionService.decryptUsingAES256(localStorage.getItem('deviceId'));
    let refreshToken = localStorage.getItem('refreshToken');
    let getToken = {
      "userId": userId,
      "deviceId": deviceId,
      "refreshToken": refreshToken
    }
    return this.dispatcher.post(this.baseUrl + 'access-token', getToken)
      .pipe(tap((res: any) => {
        if (res.status) {
          localStorage.setItem('accessToken', res.data.accessToken);
        }
      }, err => {
        localStorage.clear();
        this.router.navigateByUrl('');
      }));
  }

  refreshToken(data: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUrl + 'refreshToken', data).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  contactUS(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.baseUrl + 'contact-us', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  getMenuList() {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseUrl + 'category-web', '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

}
