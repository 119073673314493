import { FormGroup } from '@angular/forms';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import jwt_decode from 'jwt-decode';

/**
* Method: getDeviceTokenId
* Purpose : get device unquied token id
*/
export function getDeviceTokenId(): any {
  return new Promise(async (resolve, reject) => {
    const fp = await FingerprintJS.load();

    // The FingerprintJS agent is ready.
    // Get a visitor identifier when you'd like to.
    const result = await fp.get();

    const visitorId = result.visitorId;
    resolve(visitorId);

  });
}

/**
* Method: mustMatch
* Purpose : match password and confirm password
*/
export function mustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

/**
* Method: mustMatch
* Purpose : match password and confirm password
*/
export function doNotMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.doNotMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value === matchingControl.value) {
      matchingControl.setErrors({ doNotMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
/**
* Method: getDecodedAccessToken
* Purpose : get decoded access token value
*/
export function getDecodedAccessToken(token: string): any {
  try {
    return jwt_decode(token);
  }
  catch (Error) {
    return null;
  }
}

