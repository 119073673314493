// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  AesSecretKey: '08277A08B0ABA70703E28A5FCED7396D',
  AesSecretIVKey: 'D9062EA86462F77E',
  ClientSecret: 'O6tkqeExC5df9cLSQXFML3sC66G2HWbnftLs7JA6z7n4ziFOvR3I88UC2y1VF25g8ctvIY55gL01BHut8pF0grdr4YvZloNaPILOLgKi66V65BnbtM09a2ufgy5d1KcS',
  // Local
  // baseUrl:'http://10.11.7.240:3000/app/api/v1/',
  // orderBaseUrl:'http://10.11.7.240:3000/app/api/v1/',
  // inventoryBaseUrl:'http://10.11.7.240:3000/app/api/v1/',
  // baseUrl: 'https://patelliquor-dev.azurewebsites.net/app/api/v1/', //--use
  // orderBaseUrl: 'https://patelliquor-dev.azurewebsites.net/app/api/v1/',
  // inventoryBaseUrl: 'https://patelliquor-dev.azurewebsites.net/app/api/v1/',


    baseUrl: 'https://patelprod-new.azurewebsites.net/app/api/v1/',
    orderBaseUrl: 'https://patelprod-new.azurewebsites.net/app/api/v1/',
    inventoryBaseUrl: 'https://patelprod-new.azurewebsites.net/app/api/v1/',
  // baseUrl: 'https://patel-liquor-prod.azurewebsites.net/app/api/v1/', //-use
  //orderBaseUrl: 'https://patel-liquor-prod.azurewebsites.net/app/api/v1/',
//  inventoryBaseUrl: 'https://patel-liquor-prod.azurewebsites.net/app/api/v1/',

 
  blobUrl: 'https://patelproblob.blob.core.windows.net/patelmedia',
  azContainerName: 'patelmedia',
  azStorageAccount: 'patelproblob',
  azStorageDirectoryProfile: 'user-image',
  azStorageDirectoryLicence: 'licence',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
