<div mat-dialog-content class="popup">
    <div class="popup-imgs">
        <img src="assets/images/popup-top.png" alt="" class="popup-top" />
        <img src="assets/images/popup-bottom.png" alt="" class="popup-bottom" />
    </div>
    <div class="popup-inner">
        <div class="popup-header">
            <button (click)="onClose()" class="popup-close">
                <img src="assets/images/icon-close.svg" alt="" class="" />
            </button>
        </div>
        <div class="popup-max-hgt">
            <div class="popup-container pt-20">
                <div class="popup-main-img">
                    <img src="assets/images/email-sent.png" alt="" />
                </div>
                <div class="line-heading">
                    <h3>Email has been sent</h3>
                </div>
                <p class="text-gray mt-4">{{text}}</p>
                <div class="row mt-4">
                    <div class="col-md-5 col-12">
                        <button type="button" class="btn btn-primary w-100" (click)="goToLogin()">Login</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>