import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DispatcherService } from '../../utils/service-dispatcher/dispatcher.service';
import { EncryptionService } from '../encryption-service/encryption.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  orderBaseUrl!: string;
  isOrderDetailsActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private dispatcher: DispatcherService,
    private encryptionService: EncryptionService
  ) {
    this.orderBaseUrl = environment.orderBaseUrl;
  }


  addRemoveCartItems(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.orderBaseUrl + 'cart', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  getCartItems() {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.orderBaseUrl + 'cart', '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  orderPlaces(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.orderBaseUrl + 'order', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  getOrderDetails(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.orderBaseUrl + 'order', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  cancelOrder(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.put(this.orderBaseUrl + 'order-status', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  getOrderStastics(params: any) {
    
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.orderBaseUrl + 'order-stastics', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  addRemoveWishlistItems(params: any) {
    params.barId = this.encryptionService.decryptUsingAES256(localStorage.getItem('barId'));
    return new Promise((resolve, reject) => {
      this.dispatcher.post(this.orderBaseUrl + 'wishlist_web', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  clearWishlistItems() {
    return new Promise((resolve, reject) => {
      this.dispatcher.put(this.orderBaseUrl + 'wishlist-empty', '').subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  getWeeklyStats() {
      return this.dispatcher.get(this.orderBaseUrl + 'order-stats-web', '')
  }

  //this is use to get all category data
  getCategoryDetails() {
    return this.dispatcher.get(this.orderBaseUrl + 'category', '')
  }
  getBrandByCategory(categoryId : any) {
    const params = {
      categoryId : categoryId
    }
    return this.dispatcher.get(this.orderBaseUrl + 'brand', params);
  }
  getDataOnFilter(data : any) {
    const params = data;
    return this.dispatcher.get(this.orderBaseUrl + 'order-stats-web-filter', params)
  }
  getDataOnOrderFilter(data : any) {
    const params = data;
    return this.dispatcher.get(this.orderBaseUrl + 'order-history-filter', params);
  }
}
