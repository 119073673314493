<app-header></app-header>
<!-- <div class="row mt-4 p-3">
    <div class="col-lg-4">
        <div class="line-heading">
            <h3>About us</h3>
        </div>
    </div>
</div> -->
<section class="mt-3 p-3">
  <div class="container">
    <div class="row aboutus-para">
      <div class="col-12">

        <h3 class="text-primary underline">Family Owned by Ronak Patel</h3>
        <p>Ronak Patel and his wife, Vaishali Patel moved to Hardeeville, SC in 2006. They have a twelve-year-old
          daughter, Aanya Patel and an eight-year-old son, Ayaan Patel. In 2006, Ronak and his wife opened and operated
          a
          Convenience Store in downtown Hardeeville. With a vision for growth and community outreach, and the work ethic
          to support it. Ronak grew his business from one, to three Convenience Stores and two Shopping Centers. While
          managing those businesses, Ronak expanded his efforts into the Liquor Industry and decided to open “Patel's
          Liquor” in 2016. With the Grand Opening of Patel's Liquor, Ronak had one vision in mind, and that was to be
          “The
          best privately owned Liquor store that focuses on creating a unique shopping experience that's based on
          innovative technology, excellent customer service, competitive pricing, and long-term relationships”. Not only
          does Ronak pride himself on his ability to be the best at what he does, but he also takes pride in giving back
          to the Community. Which is why in February of 2020, Ronak decided to start his very own Community Service
          Foundation, called “The Patel's Foundation of the Low Country”. Ronak's Foundation donates food and supplies
          to
          local schools and offers Educational Tutoring for kids with special needs. In addition, The Patel's Foundation
          of the Low Country has partnered with “Save the Children” to provide young kids and adolescents with clothes,
          toys, and further educational services for kids affected by conflict. Ronak's Community Service efforts didn't
          go unnoticed. In 2021 Ronak was awarded the first ever “Citizen of The Year Award” also known as “The
          Heartbeat
          Award”, which recognized Ronak for his outstanding services to the community of Hardeeville. In all, Ronak
          puts
          his family, employees, customers and community first, and wants you to leave you with a quote that reflects
          his
          teachings “If you want to go fast, go alone. If you want to go far, go together”.</p>
        <h3 class="text-primary underline">About Our Employees</h3>
        <p>Ruben Chairez is our Store Manager and Managing Partner. Ruben has been with Patel's Liquor since 2016 and
          manages all store responsibilities. He has five years of experience with Wine and Spirits and has a passion
          for
          expanding his knowledge in the Liquor Industry. Outside of work, Ruben is an avid runner. He enjoys long
          distance running and biking and participates in Marathons across the country. </p>
        <p>Cody Farrier is our Class B Manager and On-Premise Sales Representative. Cody has been with Patel's Liquor
          since 2021 and oversees all Class B customers. He is responsible for bringing in new business, developing
          customer and public relations, and the overall growth of the business. Cody has over 7 years of Sales
          experience. He's a college graduate from Francis Marion University with a Business Degree in Marketing.
          Outside
          of work, Cody enjoys playing Soccer and Golf, riding his motorcycle, and spending time with his family and
          friends.</p>
        <h3 class="text-primary underline">Need to Place an Order?</h3>
        <p>We have developed an app called “Patels Liquor” that's available to download in the Apple store and in the
          Android Play Store. We also have a website www.patelsliquor.com that is integrated with the app. Whether you
          work better from your phone or from your computer, we've got you covered. Easy to access, our ordering
          platform
          gives you the power to order product, store digital invoices, keep statistics on what you've ordered and when,
          a
          notification page that keeps you up to date on product supply, price changes, new products and more. Lastly,
          we've incorporated a “Wishlist” that will allow you to load and save your entire Liquor portfolio for easier
          order placements.</p>
        <h3 class="text-primary underline">Are You Paying Too Much for Your Liquor?</h3>
        <p>Patel's Liquor has one goal in mind when it comes to pricing and that is “To provide all of our partners with
          the highest quality liquor and wine at the most affordable price”. We keep our margins lower, so you can keep
          yours higher. Don't get rolled on by other providers higher pricing and save some bills. Call us now to start
          saving money today.</p>
        <h3 class="text-primary underline">Make the Switch, You'll Thank Yourself Later</h3>
        <p>Are you nervous to discontinue your service with your current supplier? Don't be, we've got you covered. We
          offer same day deliveries Monday through Saturday, Competitive Pricing, an in-person Sales Rep, and Excellent
          Customer Service. Don't be shy and give us a try, call and make the switch today!</p>
        <h3 class="text-primary underline">Better Yet, Come See Us in Person!</h3>
        <p>We enjoy seeing our customers in person, and we want to encourage you to come in to say hello. We develop
          relationships with all our customers, and it brings us joy when you stop by to shop with us. Other places may
          consider you a customer, but here at Patel's Liquor we consider you Family!</p>
      </div>
    </div>
    <div class="row aboutus-card mt-4">
      <div class="col-md-6 col-lg-4">
        <!-- <div class="card mb-3 shadow border-0 card-border">
          <div class="row no-gutters no-gutters-about ">
            <div class="col-md-6 no-gutters-img">
              <img src="assets/images/ashutosh-bisen.jpg" class="card-img" alt="...">
            </div>
            <div class="col-md-6">
              <div class="card-body">
                <h4 class="text-danger"><b>Ashutosh Bisen</b></h4>
                <p class="card-text">In Loving Memory of Ashutosh Bisen</p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="card">
          <img src="assets/images/ashutosh-bisen.jpg" class="card-img-top" alt="...">
          <div class="card-body">
            <div class="card-text">
              <h4 class="text-danger"><b>Ashutosh Bisen</b></h4>
              <p class="card-text">In Loving Memory of Ashutosh Bisen</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <!-- <div class="card mb-3 border-0 shadow">
          <div class="row no-gutters no-gutters-about">
            <div class="col-md-6 no-gutters-img">
              <img src="assets/images/ruben-chairez.jpg" class="card-img" alt="...">
            </div>
            <div class="col-md-6">
              <div class="card-body">
                <h4 class="text-danger"><b>Ruben Chairez</b></h4>
                <p class="card-text">is our Store Manager and Managing Partner.</p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="card">
          <img src="assets/images/ruben-chairez.jpg" class="card-img-top" alt="...">
          <div class="card-body">
            <div class="card-text">
              <h4 class="text-danger"><b>Ruben Chairez</b></h4>
              <p class="card-text">is our Store Manager and Managing Partner.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <!-- <div class="card mb-3 border-0 shadow">
          <div class="row no-gutters no-gutters-about">
            <div class="col-md-6 no-gutters-img">
              <img src="../../../../../assets/images/image.jpg" class="card-img" alt="...">
            </div>
            <div class="col-md-6">
              <div class="card-body">
                <h4 class="text-danger"><b>Ronak Patel</b></h4>
                <p class="card-text">Owner</p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="card">
          <img src="../../../../../assets/images/image.jpg" class="card-img-top" alt="...">
          <div class="card-body">
            <div class="card-text">
              <h4 class="text-danger"><b>Ronak Patel</b></h4>
              <p class="card-text">Owner</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <!-- <div class="card mb-3 border-0 shadow">
          <div class="row no-gutters no-gutters-about">
            <div class="col-md-6 no-gutters-img">
              <img src="../../../../../assets/images/IMG_2685.jpeg" class="card-img" alt="...">
            </div>
            <div class="col-md-6">
              <div class="card-body">
                <h4 class="text-danger"><b>Cody Farrier</b></h4>
                <p class="card-text">Class B Manager</p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="card">
          <img src="../../../../../assets/images/IMG_2685.jpeg" class="card-img-top" alt="...">
          <div class="card-body">
            <div class="card-text">
              <h4 class="text-danger"><b>Cody Farrier</b></h4>
              <p class="card-text">Class B Manager</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>