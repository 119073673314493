<header class="navbar navbar-expand-lg navbar-default">
    <div class="container">
        <div class="">
            <div class="home-logo" [routerLink]="['/']">
                <a class="navbar-brand" href="javascript:void(0);"><img src="./assets/images/logo.png" alt=""></a>
            </div>
        </div>

        <div class="header-cart">
            <div class="header-right">
                <ul class="header-icons">
                    <!-- <li>
                            <div class="search">
                                <div class="search-img">
                                    <img src="/assets/images/search.svg" alt="" />
                                </div>
                                <input type="text" class="form-control pl-5" placeholder="Search Products"/>
                            </div>
                        </li> -->
                    <li routerLinkActive="active">
                        <a [routerLink]="['/product']">
                            <img src="assets/images/search.svg" alt="" />
                        </a>
                    </li>
                    <li routerLinkActive="active">
                        <a [routerLink]="['/users/wishlist']">
                            <img src="assets/images/heart.svg" alt="" />
                        </a>
                    </li>
                    <li routerLinkActive="active">
                        <a [routerLink]="['/cart']">
                            <img src="assets/images/cart.svg" alt="" />
                            <span *ngIf="counter > 0" class="cart-count">{{counter}}</span>
                        </a>
                    </li>
                </ul>
                <div *ngIf="isLogin == true" class="user-sec" [routerLink]="['/users/profile']">
                    <div class="user-img">
                        <img src={{profileImage}} onerror="this.src='assets/images/user-placeholder.png';" alt="" />
                    </div>
                    <p class="user-name">Me</p>
                </div>
            </div>
        </div>
        <div class="hamburger">
            <div class="icon"></div>
        </div>
    </div>
</header>
<div class="megamenu-main">
    <div class="container">
        <div class="navbar px-0">
            <div class="main-nav navbar-collapse js-navbar-collapse">
                <ul class="nav navbar-nav">
                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <a [routerLink]="['']" class="nav-link">Home</a>
                    </li>
                    <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
                        <a [routerLink]="['/about-us']" class="nav-link">About Us</a>
                    </li>
                    <ng-container *ngIf="menuList">
                        <ng-container *ngFor="let master of menuList">
                            <li class="nav-item dropdown cursor-pointer" routerLinkActive="active"
                                [routerLinkActiveOptions]="{ exact: true }">
                                <a (click)="goToMasterCategory(master)">{{master.masterCategoryName}}</a>
                                <div class="megamenu-dropdown">
                                    <div class="megamenu-submenus">
                                        <ul class="col">
                                            <li *ngFor="let parent of master.category">
                                                <a (click)="goToCategory(parent)">{{parent.categoryName}}</a>
                                                <ul *ngIf="parent.categoryName=='New & Trending'"
                                                    class="megamenu-submenu-left">
                                                    <li *ngFor="let child of master.categorySection">
                                                        <a class="cursor-pointer"
                                                            (click)="goToProductList(child)">{{child.categorySectionName}}</a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                        </ng-container>
                    </ng-container>
                    <li *ngIf="isLogin == true" class="nav-item">
                        <a href="javascript:void(0);" class="nav-link" (click)="openLogoutDialog()">Logout</a>
                    </li>
                    <li *ngIf="isLogin == false" class="nav-item">
                        <a href="javascript:void(0);" class="nav-link" (click)="openLoginDialog()">Login</a>
                    </li>
                    <li *ngIf="isLogin == false" class="nav-item">
                        <a href="javascript:void(0);" class="nav-link" (click)="openSignupDialog()">Signup</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>