<!-- loader -->
<div class="loader-wrapper" *ngIf="show">
    <div class="site-loader">
      <div class="loader-inner ball-spin-fade-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>