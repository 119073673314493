import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { messages } from '../../../core/constants/messages';
import { AuthService } from '../../../core/services/auth-service/auth.service';
import { EncryptionService } from '../../../core/services/encryption-service/encryption.service';
import { LoaderService } from '../../../shared/loader/loader.service';
import { EmailSentComponent } from '../email-sent/email-sent.component';
import { TermsConditionsComponent } from '../terms-conditions/terms-conditions.component';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  isSubmitted = false;
  signUpForm!: FormGroup;
  passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*{}<>_\[\]]{1}).{8,50}$/;
  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SignUpComponent>,
    private authService: AuthService,
    private injector: Injector,
    private encryptionService: EncryptionService,
  ) { }

  ngOnInit(): void {
    this.initializationForm();
  }

  initializationForm() {
    this.signUpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
      barName: ['', Validators.required],
    });
  }

  get f() { return this.signUpForm.controls; }


  onClose() {
    this.dialogRef.close({ isLogin: false });
  }

  goToLogin() {
    this.dialogRef.close({ isLogin: false });
    this.authService.popupEventReceived.next({
      gologin: true
    })
  }

  /**
  * Method: openTermsConditionsDialog
  * Purpose : open Terms Conditions dialog
  */
  openTermsConditionsDialog(): void {
    this.dialog.open(TermsConditionsComponent, {
      width: '620px',
      height: '600px'
    });
  }

  /**
  * Method: openEmailSentDialog
  * Purpose : open email sent dialog
  */
  openEmailSentDialog(): void {
    this.dialog.open(EmailSentComponent, {
      width: '620px',
      height: '445px',
      data: {
        text: messages.VERIFY_YOUR_ACCOUNT + this.signUpForm.value.email + messages.KINDLY_VERIFY
      }
    });
  }


  onSubmit() {
    this.isSubmitted = true;
    if (this.signUpForm.invalid) {
      return;
    } else {
      this.injector.get(LoaderService).show();
      let request = {
        'barName': this.signUpForm.value.barName,
        'emailId': this.signUpForm.value.email,
        'password': this.encryptionService.encryptUsingAES256(this.signUpForm.value.password),
        'userType': 'B2C'
      }
      this.authService.signup(request).then((res: any) => {
        this.injector.get(LoaderService).hide();
        if (res) {
          this.dialogRef.close();
          this.openEmailSentDialog();
        }
      }).catch(err => {
        this.injector.get(LoaderService).hide();
      });
    }
  }
}
