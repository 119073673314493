<div mat-dialog-content class="popup">
    <div class="popup-imgs">
        <img src="assets/images/popup-top.png" alt="" class="popup-top" />
        <img src="assets/images/popup-bottom.png" alt="" class="popup-bottom" />
    </div>
    <div class="popup-inner">
        <div class="popup-header">
            <button (click)="onClose()" class="popup-close">
                <img src="assets/images/icon-close.svg" alt="" class="" />
            </button>
        </div>
        <div class="popup-max-hgt">
            <div class="popup-container pt-20">
                <div class="line-heading">
                    <h3>Logout</h3>
                </div>
                <p class="text-gray mt-4">Are you sure you want to logout from the app?</p>
                <div class="row mt-4">
                    <div class="col-md-5 col-6">
                        <button type="button" class="btn btn-primary w-100" (click)="onLogout()">Yes</button>
                    </div>
                    <div class="col-md-5 col-6">
                        <button type="button" class="btn w-100" (click)="onClose()">Cancel</button>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</div>