import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ToastMessage } from '../toast/toast';
import { Toastr } from '../../enums/enums';

@Injectable({
  providedIn: 'root'
})
export class DispatcherService {

  constructor(private http: HttpClient) { }

  /**
* Method: get(urlEndPoint)
* Purpose: sending httpClient req and getting response from server
* created on: 19-sep-2019
*/
  get(urlEndPoint: any, data: any) {
    return this.http.get(urlEndPoint, { params: data }).pipe(
      tap(res => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  /**
  * Method: post(urlEndPoint,data)
  * Purpose: sending httpClient req and getting response from server
  * created on: 19-sep-2019
  */
  post(urlEndPoint: any, data: any) {
    return this.http.post(urlEndPoint, data).pipe(
      tap(res => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  /**
  * Method: delete(urlEndPoint,data)
  * Purpose: sending httpClient delete req and getting response from server
  * created on: 19-sep-2019
  */
  delete(urlEndPoint: any, data: any) {
    return this.http.delete(urlEndPoint, { params: data }).pipe(
      tap(res => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  /**
  * Method: put(urlEndPoint,data)
  * Purpose: sending httpClient delete req and getting response from server
  * created on: 19-sep-2019
  */
  put(urlEndPoint: any, data: any) {
    return this.http.put(urlEndPoint, data).pipe(
      tap(res => {
        return res;
      }),
      catchError(this.handleError)
    );
  }

  // Error 
  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      if (error.error && error.error.message && error.status == 404) {
        return throwError(error);

      }
      msg = error.error && error.error.message ? error.error.message : error.message;
    }
    ToastMessage.fire({ title: msg, icon: Toastr.ERROR });
    return throwError(error);
  }
}

