import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './components/login/login.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { EmailSentComponent } from './components/email-sent/email-sent.component';
import { LogoutComponent } from './components/logout/logout.component';
import { UnderReviewComponent } from './components/under-review/under-review.component';


@NgModule({
  declarations: [
    LoginComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    TermsConditionsComponent,
    EmailSentComponent,
    LogoutComponent,
    UnderReviewComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule
  ],
  exports: [
    LoginComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LogoutComponent,
    UnderReviewComponent
  ],
  entryComponents: [
    TermsConditionsComponent,
    ForgotPasswordComponent,
    EmailSentComponent,
    ResetPasswordComponent,
    LogoutComponent,
    UnderReviewComponent
  ]
})
export class AuthModule { }
