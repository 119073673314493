import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DispatcherService } from '../../utils/service-dispatcher/dispatcher.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  baseUrl!: string;

  constructor(
    private dispatcher: DispatcherService,
  ) {
    this.baseUrl = environment.baseUrl;
  }

  /**
  * method: getProfileDetail
  * description : get user profile details 
  */
  getProfileDetail(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseUrl + 'user-profile', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * method: changePassword
  * description : change password 
  */
  changePassword(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.put(this.baseUrl + 'change-password', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * method: isFileImage
  * description : check if file is of type image 
  */
  isFileImage(file: any) {
    return file && file['type'].split('/')[0] === 'image';
  }

  /**
  * method: updateUserProfile
  * description : update user profile
  */
  updateUserProfile(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.put(this.baseUrl + 'user-profile', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * method: getNotificationList
  * description : get user notification list 
  */
  getNotificationList(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseUrl + 'notification', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  /**
  * method: getWishList
  * description : get wish list 
  */
  getWishList(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.get(this.baseUrl + 'wishlist_web', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  reorderProduct(params : any) {
      return this.dispatcher.put(this.baseUrl + 'wishlist-item-rearrange', params);
  }


  
  deleteAccount(params: any) {
    return new Promise((resolve, reject) => {
      this.dispatcher.put(this.baseUrl + 'remove-manager', params).subscribe((res: any) => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }


}
