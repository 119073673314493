<div mat-dialog-content class="popup">
    <div class="popup-imgs">
        <img src="assets/images/popup-top.png" alt="" class="popup-top" />
        <img src="assets/images/popup-bottom.png" alt="" class="popup-bottom" />
    </div>
    <div class="popup-inner">
        <div class="popup-header">
            <button (click)="onClose()" class="popup-close">
                <img src="assets/images/icon-close.svg" alt="" class="" />
            </button>
        </div>
        <div class="popup-max-hgt">
            <div class="popup-container pt-20">
                <div class="popup-main-img">
                    <img src="assets/images/forgot.png" alt="" />
                </div>
                <div class="line-heading">
                    <h3>Reset Password</h3>
                </div>              
                <form [formGroup]="resetPasswordForm" class="mt-4">
                    <div class="form-group">
                        <label for="email">New Password</label>
                        <input type="password" formControlName="newPassword"
                            [ngClass]="{ 'is-invalid': isSubmitted && f.newPassword.errors }"
                            class="form-control" id="newPassword" placeholder="New Password">
                        <div *ngIf="isSubmitted && f.newPassword.errors" class="invalid-feedback">
                            <div *ngIf="f.newPassword.errors.required">password is required</div>
                            <div *ngIf="f.newPassword.errors.pattern">
                                <p>Password must be at least 6 characters and should be a mix of special
                                    characters, lowercase and uppercase letters (like Anj1G@de)</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="email">Confirm Password</label>
                        <input type="password" formControlName="confirmPassword"
                            [ngClass]="{ 'is-invalid': isSubmitted && f.confirmPassword.errors }"
                            class="form-control" id="confirmPassword" placeholder="Confirm Password">
                        <div *ngIf="isSubmitted && f.confirmPassword.errors" class="invalid-feedback">
                            <div *ngIf="f.confirmPassword.errors.required">Confirm password is
                                required</div>
                            <div *ngIf="f.confirmPassword.errors.mustMatch">Confirm password does not
                                match with new password</div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-5 col-12">
                            <button type="button" class="btn btn-primary w-100" (click)="onSubmit()">Submit</button>
                        </div>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>