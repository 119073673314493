import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../../../auth/components/login/login.component';
import { SignUpComponent } from '../../../auth/components/sign-up/sign-up.component';
import { AuthService } from '../../../core/services/auth-service/auth.service';
import { LogoutComponent } from '../../../auth/components/logout/logout.component';
import { environment } from '../../../../environments/environment';
import * as commonFunction from '../../../core/utils/common-functions/common-functions';
import { Router } from '@angular/router';
import { SharedService } from '../../../core/services/shared/shared.service';
import { LoaderService } from '../../loader/loader.service';
import { OrderService } from '../../../core/services/order/order.service';
import { UnderReviewComponent } from 'src/app/auth/components/under-review/under-review.component';
import { EncryptionService } from '../../../core/services/encryption-service/encryption.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  isLogin: boolean = false;
  firstName: string = '';
  lastName: string = '';
  profileImage: string = '';
  cartData: any = [];
  counter = 0;
  menuList: any = []

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private router: Router,
    private sharedService: SharedService,
    private injector: Injector,
    private orderService: OrderService,
    private encryptionService: EncryptionService
  ) {

    // this methoed is called open the popup 
    this.authService.popupEventReceived.subscribe(data => {
      if (data?.goSignUp) {
        this.openSignupDialog();
      }
      if (data?.gologin) {
        this.openLoginDialog();
      }
    });

    this.authService.loginLogoutEventReceived.subscribe((res) => {
      if (res?.isLogin) {
        this.getCartItems();
      }
      if (res?.isLogout) {
        this.sharedService.cartDataCount = 0;
        this.sharedService.shareCartData.next('');
      }
    })

    this.fetchShareCartData();
    this.sharedService.shareProfileImage.subscribe(res => {
      this.profileImage = res;
    })

    this.authService.underReviewPopupEvent.subscribe((res) => {
      if (res?.isUnderReview) {
        this.openUnderReviewDialog();
      }
    })
  }

  private fetchShareCartData() {
    this.sharedService.shareCartData.subscribe(data => {
      if (this.cartData.find(obj => obj.productId == data.productId)) {
        this.cartData.find(obj => obj.productId == data.productId).quantity = data.quantity;
      } else {
        this.cartData.push(data);
      }
      this.counter = this.sharedService.cartDataCount;
    });
  }

  ngOnInit(): void {
    this.getMenuList()
    const token = commonFunction.getDecodedAccessToken(localStorage.getItem("accessToken"))
    this.isLogin = token ? true : false;
    if (this.isLogin) {
      this.getCartItems();
    }
    this.firstName = localStorage.getItem('firstName');
    this.lastName = localStorage.getItem('lastName');
    this.profileImage = localStorage.getItem('userImage') ? environment.blobUrl + "/user-image/" + localStorage.getItem('userImage') : '';
  }

  /**
  * Method: openLoginDialog
  * Purpose : open login dialog
  */
  openLoginDialog(): void {
    const dialogRef = this.dialog.open(LoginComponent, {
      width: '620px',
      height: '445px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLogin = result.isLogin;
        this.firstName = localStorage.getItem('firstName');
        this.lastName = localStorage.getItem('lastName');
        this.profileImage = localStorage.getItem('userImage') ? environment.blobUrl + "/user-image/" + localStorage.getItem('userImage') : '';
      }
    });
  }

  /**
  * Method: openSignupDialog
  * Purpose : open signup dialog
  */
  openSignupDialog(): void {
    const dialogRef = this.dialog.open(SignUpComponent, {
      width: '620px',
      maxHeight: '600px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      }
    });
  }

  /**
  * Method: openLogoutDialog
  * Purpose : open logout dialog
  */
  openLogoutDialog(): void {
    const dialogRef = this.dialog.open(LogoutComponent, {
      width: '620px',
      height: '300px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result?.isLogout) {
        localStorage.clear();
        this.isLogin = false;
        if (this.router.url == '/') {
          this.authService.loginLogoutEventReceived.next({ isLogin: false, isLogout: true, currentPath: this.router.url });
        } else {
          this.router.navigate(['/'])
          this.authService.loginLogoutEventReceived.next({ isLogin: false, isLogout: true, currentPath: this.router.url });
        }
      }
    });
  }

  /**
  * Method : getCartItems
  * Purpose : get order items 
  */
  getCartItems() {
    this.injector.get(LoaderService).show();
    this.orderService.getCartItems().then((res: any) => {
      this.injector.get(LoaderService).hide();
      let counter = 0;
      for (const obj of res.data) {
        counter += obj.quantity;
      }
      this.sharedService.cartDataCount = counter;
      this.counter = this.sharedService.cartDataCount;
    }).catch(err => {
      this.injector.get(LoaderService).hide();
      console.log(err);
    })
  }

  /**
  * Method : getMenuList
  * Purpose : get menu list
  */
  getMenuList() {
    this.injector.get(LoaderService).show();
    this.authService.getMenuList().then((res: any) => {

      this.injector.get(LoaderService).hide();
      this.menuList = res.data;
    }).catch(err => {
      this.injector.get(LoaderService).hide();
      console.log(err);
    })
  }

  /**
  * Method: openUnderReviewDialog
  * Purpose : open Under Review Dialog
  */
  openUnderReviewDialog(): void {
    this.dialog.open(UnderReviewComponent, {
      width: '600px',
      height: '400px'
    });
  }

  goToProductList(item: any) {
    this.injector.get(LoaderService).show();
    const request = {
      id: item.categorySectionId,
      recordType: "SECTION_ID"
    }
    this.router.navigate(['/product'], { queryParams: { data: this.encryptionService.encryptUsingAES256(JSON.stringify(request)) } });
    this.injector.get(LoaderService).hide();
    // this.router.navigate(['/product'], { queryParams: { category: this.encryptionService.encryptUsingAES256(item.categoryId) } });
  }

  goToMasterCategory(item) {
    const request = {
      id: item.masterCategoryId,
      categoryIds: item.category.map((obj) =>  obj.categoryId ).join(','),
      recordType: "MASTER_ID"
    }
    this.router.navigate(['/product'], { queryParams: { data: this.encryptionService.encryptUsingAES256(JSON.stringify(request)) } });
  }

  goToCategory(item) {
    const request = {
      id: item.categoryId,
      recordType: "CATEGORY_ID"
    }
    this.router.navigate(['/product'], { queryParams: { data: this.encryptionService.encryptUsingAES256(JSON.stringify(request)) } });
  }
}
