import { Component, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { messages } from '../../../core/constants/messages';
import { AuthService } from '../../../core/services/auth-service/auth.service';
import { LoaderService } from '../../../shared/loader/loader.service';
import { EmailSentComponent } from '../email-sent/email-sent.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm!: FormGroup;
  isSubmitted: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public dialog: MatDialog,
    private injector: Injector,
  ) { }

  ngOnInit(): void {
    this.initializationForm()
  }


  initializationForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  get f() { return this.forgotPasswordForm.controls; }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.isSubmitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    } else {
      this.injector.get(LoaderService).show();
      let request = {
        'emailId': this.forgotPasswordForm.value.email,
      }
      this.authService.forgotPassword(request).then((res: any) => {
        this.injector.get(LoaderService).hide();
        if (res) {
          this.dialogRef.close();
          this.openEmailSentDialog();
        }
      }).catch(err => {
        this.injector.get(LoaderService).hide();
      });
    }
  }

  /**
  * Method: openEmailSentDialog
  * Purpose : open email sent dialog
  */
  openEmailSentDialog(): void {
    this.dialog.open(EmailSentComponent, {
      width: '620px',
      height: '445px',
      data: {
        text: messages.RESET_YOUR_PASSWORD
      }
    });
  }

}
