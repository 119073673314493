import { Component, Inject, Injector, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Toastr } from '../../../core/enums/enums';
import { AuthService } from '../../../core/services/auth-service/auth.service';
import { EncryptionService } from '../../../core/services/encryption-service/encryption.service';
import { mustMatch } from '../../../core/utils/common-functions/common-functions';
import { ToastMessage } from '../../../core/utils/toast/toast';
import { LoaderService } from '../../../shared/loader/loader.service';
import { PasswordStrengthValidator } from '../validators/password-validator';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm!: FormGroup;
  isSubmitted: boolean = false;
  // passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[#$^+=!*()@%&]).{6,50}$/;
  // !@#$%^&*
  passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*{}<>_\[\]]{1}).{8,50}$/;

  constructor(
    public dialogRef: MatDialogRef<ResetPasswordComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    public dialog: MatDialog,
    private injector: Injector,
    private encryptionService: EncryptionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initializationForm()
  }


  initializationForm() {
    this.resetPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
      confirmPassword: ['', [Validators.required]],
    },
      {
        validator: mustMatch('newPassword', 'confirmPassword')
      });
  }

  get f() { return this.resetPasswordForm.controls; }

  onClose() {
    this.dialogRef.close();
  }

  goToLogin() {
    this.dialogRef.close({ isLogin: false });
    this.authService.popupEventReceived.next({
      gologin: true
    })
  }

  onSubmit(): void {
    this.isSubmitted = true;
    // console.log(this.resetPasswordForm);
    //return;
    if (this.resetPasswordForm.invalid) {
      return;
    } else {
      this.injector.get(LoaderService).show();
      const request = {
        password: this.encryptionService.encryptUsingAES256(this.resetPasswordForm.value.newPassword),
        confirmPassword: this.encryptionService.encryptUsingAES256(this.resetPasswordForm.value.confirmPassword),
        token: this.data.token
      };
      this.authService.resetPassword(request).then((res: any) => {
        this.injector.get(LoaderService).hide();
        if (res) {
          this.dialogRef.close();
          ToastMessage.fire({ title: res.message, icon: Toastr.SUCCESS });
          this.router.navigateByUrl('');
          this.goToLogin();
        }
      }).catch(err => {
        this.injector.get(LoaderService).hide();
      });
    }
  }

  /**
  * Method: openEmailSentDialog
  * Purpose : open email sent dialog
  */
  // openEmailSentDialog(): void {
  //   this.dialog.open(EmailSentComponent, {
  //     width: '670px',
  //     height: '60%',
  //     data: {
  //       text: messages.RESET_YOUR_PASSWORD
  //     }
  //   });
  // }
}
