import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LoaderState } from './loader';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  // private loaderSubject = new BehaviorSubject(<LoaderState>{});
  private loaderSubject: Subject<LoaderState> = new Subject<LoaderState>();
  loaderState = this.loaderSubject.asObservable();
  constructor() { }

  /**
  * Method : show
  * Purpose : Show the loader
  */
  show() {
    this.loaderSubject.next(<LoaderState>{ show: true });
  }

  /**
  * Method : hide
  * Purpose : Hide the loader
  */
  hide() {
    this.loaderSubject.next(<LoaderState>{ show: false });
  }
  
}
