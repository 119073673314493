import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-under-review',
  templateUrl: './under-review.component.html',
  styleUrls: ['./under-review.component.css']
})
export class UnderReviewComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UnderReviewComponent>,
  ) { }

  ngOnInit(): void {    
  }

  onClose() {
    this.dialogRef.close();
  }

}
